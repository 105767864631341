import React from "react";

function Screenshot({src, title}) {
    const full = `/images/screenshots/${src}`;
    const thumbnail = `/images/thumbnails/${src}`;

    return <div className='screenshot'>
        <h3>{title}</h3>
        <a href={full}><img src={thumbnail} style={{height: '8em'}} alt={title}/></a>
    </div>
}


export function Screenshots() {
    const screenshots = [
        {src: 'WROLPi Screenshot - Dashboard.jpg', title: 'Dashboard'},
        {src: 'WROLPi Screenshot - Custom Tags.jpg', title: 'Custom Tags'},
        {src: 'WROLPi Screenshot - Videos.jpg', title: 'Videos'},
        {src: 'WROLPi Screenshot - Status.jpg', title: 'System Status'},
        {src: 'WROLPi Screenshot - Downloads.jpg', title: 'Downloads'},
        {src: 'WROLPi Screenshot - Files.jpg', title: 'Files'},
        {src: 'WROLPi Screenshot - File Preview.jpg', title: 'File Preview'},
        {src: 'WROLPi Screenshot - Zims Manage.jpg', title: 'Kiwix Subscriptions'},
    ];

    return <>
        <div className='centered'>
            <h1>Screenshots</h1>
            <div className='features'>
                {screenshots.map(i => <Screenshot key={i['src']} {...i}/>)}
            </div>
        </div>
    </>
}
