import React from "react";

export function FAQ() {
    const gettingStartedFAQs = [
        ['wrolpi-on-android', 'Does WROLPi work on Android? Windows? MacOS?', 'WROLPi is software installed on a Linux system. It can be accessed from any modern Operating System and Browser.'],
        ['recommended-specs', 'What is the recommended Raspberry Pi?', 'A Raspberry Pi 4 with 4GB+ of RAM is recommended.  A quality aftermarket cooler is also required.'],
        ['price', 'How much does WROLPi cost?', 'WROLPi is free!'],
        ['sd-card-size', 'How much space is required?', 'An SD card with at least 32GB is recommended.'],
        ['alternative-system', 'Do I have to use a Raspberry Pi?', 'No, you can install WROLPi on Debian.  An old laptop is a good choice.'],
        ['install-windows', 'Can I install WROLPi on Windows? MacOS?', 'No. WROLPi can only be installed on Debian Linux, or Raspberry Pi OS.'],
        ['install-phone', 'Can I install WROLPi on my phone?', 'No. WROLPi can only be installed on Debian Linux, or Raspberry Pi OS.  However, it can be accessed using you\'re phone\'s browser.'],
        ['app', 'Is there a phone App?', 'No. WROLPi is installed on Linux, then accessed in your phone\'s browser.'],
        ['js', 'Is javascript required?', 'Yes. WROLPi uses a modern React interface.'],
        ['external-drive', 'Is an external hard drive required?', 'No, but an external drive is recommended to safeguard your media.'],
        ['download', 'Where can I download WROLPi?', <>WROLPi can be downloaded from <a
            href='https://wrolpi.org'>wrolpi.org</a></>],
        ['install', 'How do I install WROLPi?', <>Instructions for installing WROLPi can be found on the <a
            href='/getting-started'>Getting Started page</a></>],
        ['recommended-debian', 'What computer is recommended for Debian?', 'A laptop is recommended because it has a built in battery backup, keyboard, mouse, and screen.  However, WROLPi can be installed on any x86_64 Debian 12 system.'],
    ];
    const myWROLPiFAQs = [
        ['high-load-low-cpu', 'I have high load, but low CPU usage.', 'Your SD card may be overwhelmed, consider upgrading the speed of your SD card.'],
        ['fan-required', 'My WROLPi is extremely slow on Raspberry Pi.', 'Your Raspberry Pi may be overheated.  A quality aftermarket cooler is a necessity.'],
    ];

    const allFAQs = [...gettingStartedFAQs, ...myWROLPiFAQs];

    return <div className='left-margin'>
        <h1>Frequently Asked Questions</h1>

        <h3>Before I start</h3>
        <ul>
            {gettingStartedFAQs.map(i => <li key={i[0]} className='summary'>
                <a href={`#${i[0]}`}>{i[1]}</a>
            </li>)}
        </ul>

        <h3>My WROLPi</h3>
        <ul>
            {myWROLPiFAQs.map(i => <li key={i[0]}>
                <a href={`#${i[0]}`}>{i[1]}</a>
            </li>)}
        </ul>

        <hr className='break'/>

        <ul>
            {allFAQs.map(i => <React.Fragment key={i[0]}>
                <li className='question' id={i[0]}>{i[1]}</li>
                <ul>
                    <li className='answer'>{i[2]}</li>
                </ul>
            </React.Fragment>)}
        </ul>
    </div>
}
