import './App.css';
import {createBrowserRouter, createRoutesFromElements, Link, Outlet, Route, RouterProvider} from "react-router-dom";
import React from "react";
import {ExternalLink} from "./Components/Common";
import {Home} from "./Components/Home";
import {FAQ} from "./Components/FAQ";
import {GettingStarted} from "./Components/GettingStarted";
import {Screenshots} from "./Components/Screenshots";

function PageNotFound() {
    return <React.Fragment>
        <h1>Page Not Found!</h1>
        <p>The page you requested cannot be found</p>
    </React.Fragment>
}

function Navbar() {
    return <header className='navbar'>
        <Link to='/' className='left'>Home</Link>

        <ExternalLink href='https://github.com/lrnselfreliance/wrolpi' className='right'>GitHub</ExternalLink>
        <ExternalLink href='https://www.youtube.com/@wrolpi' className='right'>YouTube</ExternalLink>
        <ExternalLink href='https://discord.gg/HrwFk7nqA2' className='right'>Discord</ExternalLink>
    </header>
}

function Root() {
    return <>
        <Navbar/>
        <Outlet/>
    </>
}

const router = createBrowserRouter(createRoutesFromElements(<Route
    path='/'
    element={<Root/>}
    errorElement={<PageNotFound/>}
>
    <Route index element={<Home/>}/>
    <Route path='/faq' element={<FAQ/>}/>
    <Route path='/getting-started' element={<GettingStarted/>}/>
    <Route path='/screenshots' element={<Screenshots/>}/>
</Route>));

function App() {
    return <RouterProvider router={router}/>
}

export default App;
